import React, { useState, useEffect } from 'react';
import { Col, Row, Button, Divider, Upload, notification, Spin, Modal } from 'antd';
import { CheckCircleOutlined, DownloadOutlined, MailOutlined, ExclamationOutlined } from '@ant-design/icons';
import { PDFDocument } from 'pdf-lib';
import {useDispatch } from 'react-redux';
import Text from '../../components/Text';
import Icon from '../../components/Icon';
import { SuccessBox } from './styles';
import { LoadPdfTemlik ,LoadPdfTemlikDenizFactoring, LoadPdfTemlikDyb, LoadPdfTemlikAkbank } from '../../utils/file';
import { uploadTemlikInvoicesApi, uploadTemlikInvoicesPhotoApi, previewConveyanceApi } from '../../apiServices/supplierFinanceApi';
import {  fetchUserRisks, assignmentDownloaded } from '../../apiServices/userApi';
import {setRisk } from '../../store/reducers/supplierFinanceSlice'
import LocalStorageService from '../../services/LocalStorageService';


const SuccessMsg = ({ invoiceData, supplierId, discountId, financialInstitutionName, financialInstitutionTaxId }) => {
    const [isLoadingTemlikFile, setIsLoadingTemlikFile] = useState(false);
    const [pageLoading, setPageLoading] = useState(false);
    const [mailLoading, setMailLoading] = useState(false);
    const [temlikFile, setTemlikFile] = useState([]);
    const [data, setData] = useState([])
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const btn1= 'indir';
    const btn2= 'email';

    const [visible, setVisible] = useState(false);
    const [pdfUrl, setPdfUrl] = useState(null);
    
    const handleTemlikDownload = async (btnId) => { 
        if(financialInstitutionTaxId === '2910141668')
        {
            if(btnId==='email'){
                setMailLoading(true);
               
                const res = await LoadPdfTemlikDenizFactoring(invoiceData, btnId, supplierId, discountId, financialInstitutionName);
                    if(res){
                        setMailLoading(false);
                    }
              
            }else{
                await LoadPdfTemlikDenizFactoring(invoiceData, btnId, supplierId, discountId,financialInstitutionName);  
                await assignmentDownloaded({
                    applicationNumber : invoiceData.number,
                    invoiceNumbers : data
                })  
            }
        } else if(financialInstitutionTaxId === '2651554884'){
            if(btnId==='email'){
                setMailLoading(true);
               
                const res = await LoadPdfTemlikDyb(invoiceData, btnId, supplierId, discountId, financialInstitutionName);
                    if(res){
                        setMailLoading(false);
                    }
              
            }else{
                await LoadPdfTemlikDyb(invoiceData, btnId, supplierId, discountId,financialInstitutionName);  
                await assignmentDownloaded({
                    applicationNumber : invoiceData.number,
                    invoiceNumbers : data
                })  
            }
        }
        else if(financialInstitutionTaxId === '0150015264'){
            if(btnId==='email'){
                setMailLoading(true);
               
                const res = await LoadPdfTemlikAkbank(invoiceData, btnId, supplierId, discountId, financialInstitutionName);
                    if(res){
                        setMailLoading(false);
                    }
              
            }else{
                await LoadPdfTemlikAkbank(invoiceData, btnId, supplierId, discountId,financialInstitutionName);  
                await assignmentDownloaded({
                    applicationNumber : invoiceData.number,
                    invoiceNumbers : data
                })  
            }
        }
         else if(financialInstitutionTaxId !== '2910141668' || financialInstitutionTaxId !== '2651554884' ){
            if(btnId==='email'){
                setMailLoading(true);
               
                const res = await LoadPdfTemlik(invoiceData, btnId, supplierId, discountId, financialInstitutionName);
                    if(res){
                        setMailLoading(false);
                    }
              
            }else{
                 await LoadPdfTemlik(invoiceData, btnId, supplierId, discountId,financialInstitutionName);   
                await assignmentDownloaded({
                    applicationNumber : invoiceData.number,
                    invoiceNumbers : data
                })  
            }
        }
       

       
    };

    const getRisks =  async () => {
        const response = await fetchUserRisks(LocalStorageService.getAuthToken());
        if (response) {
          LocalStorageService.setBuyerRisks(response.supplierRisk);
          LocalStorageService.setBuyerLimit(response.supplierLimit);
          dispatch(setRisk(response))
        }
    };
  
    const uploadTemlikFile = async (files) => {
        setPageLoading(true);
        const areAllPdfFiles = files.every(file => file.type === 'application/pdf');
        const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png'];
        const areAllJpgFiles = files.every(file => allowedTypes.includes(file.type));
        const formData = new FormData();

        if(areAllPdfFiles || areAllJpgFiles){
            setIsLoadingTemlikFile(true);
            files.forEach((file) => {
                const fileData = file.originFileObj || file; 
                formData.append('Files', fileData, file.name);
            });
            try{
               const response = await previewConveyanceApi(formData);
               const pdfBytes = Uint8Array.from(atob(response.conveyancePdfFile), (c) => c.charCodeAt(0));
               const pdfDoc = await PDFDocument.load(pdfBytes);
               const pdfDataUri = await pdfDoc.saveAsBase64({ dataUri: true });
               setPdfUrl(pdfDataUri);
               setVisible(true);
               setPageLoading(false);
            }catch(e){
                console.log(e)
            }
        }
     
        else{
            setPageLoading(false);
            setTemlikFile([]);
            setIsLoadingTemlikFile(false);
            notification.warning({
                message: 'Uyarı',
                description: (
                  <div className='common-err'>
                      Yalnızca <strong>PDF, JPG, JPEG ve PNG</strong> uzantılı dosyaları yükleyebilirsiniz. Ayrıca, <strong> PDF </strong> uzantılı dosyalar ile diğer uzantılı dosyaları <strong>aynı anda yükleyemezsiniz.</strong>
                </div>
                ),
                duration: 8
              });
        }
    };

    const uploadTemlikFileSend = async(files) => {
        setPageLoading(true);
        const areAllPdfFiles = files.every(file => file.type === 'application/pdf');
        const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png'];
        const areAllJpgFiles = files.every(file => allowedTypes.includes(file.type));
        const formData = new FormData();
        setVisible(false);

        if(areAllPdfFiles){
            setIsLoadingTemlikFile(true);
            try{
                files.forEach((file) => {
                    const fileData = file.originFileObj || file; 
                    formData.append('Files', fileData, file.name);
                });
                await uploadTemlikInvoicesApi(formData, supplierId, discountId);
                setIsLoadingTemlikFile(false);
                notification.success({ message: 'Başarıyla yüklendi.' }); 
                setTimeout(()=>{
                    window.location.reload(false);
                }, 800); 
                setTemlikFile([]);
                getRisks();
                setPageLoading(false);
                } catch (error) {
                    console.log(error)
                    setTemlikFile([]);
                    setIsLoadingTemlikFile(false);
                    notification.error({
                    message: 'Bir hata oluştu.',
                    duration: 3,
                });
         
            }
        } else if(areAllJpgFiles){
            setIsLoadingTemlikFile(true);
            files.forEach((file) => {
                const fileData = file.originFileObj || file; 
                formData.append('imageList', fileData, file.name);
            });

            try{
                await uploadTemlikInvoicesPhotoApi(formData, supplierId, discountId);
                setIsLoadingTemlikFile(false);
                notification.success({ message: 'Başarıyla yüklendi.' }); 
                setTimeout(()=>{
                    window.location.reload(false);
                }, 800); 
                setTemlikFile([]);
                getRisks();
                setPageLoading(false);
                } catch (error) {
                    console.log(error)
                    setTemlikFile([]);
                    setIsLoadingTemlikFile(false);
                    notification.error({
                    message: 'Bir hata oluştu.',
                    duration: 3,
                });
          }
        }    
    }

      const handleRemove = (file) => {
        const updatedFileList = temlikFile.filter((item) => item.uid !== file.uid);
        setTemlikFile(updatedFileList); // Silinen dosyayı listeden çıkar
        setShow(false)
      };
    const modalClear = () => {
        setVisible(false);
        setTemlikFile([])
    }

    useEffect(() => {
        setData(
            invoiceData.invoices.length &&  invoiceData.invoices.filter((val) => (val.status === 'SECILDI'
            )).map((val) => (val.invoiceNumber))
           )
    },[])

 
    useEffect(() => {
       if(temlikFile?.length > 0){
        uploadTemlikFile(temlikFile)
       }
       
    },[temlikFile])

    return (
        <>
        <Spin tip={pageLoading ? 'Dosya yükleniyor.Lütfen bekleyiniz.': 'Temlikname e-postanıza gönderiliyor.'} spinning={pageLoading || mailLoading} >
            <Row>
                <Col xl={24}>
                    <SuccessBox>
                        <CheckCircleOutlined className="tick-icon" />
                        <Text>Onayınız finans kurumuna bildirildi.</Text>
                        <Text style={{ fontWeight: 'bold' }}>İşlemi tamamlamak için:</Text>
                        <Divider type="vertical" className="files-divider" />
                        <div className="num-round">1</div>
                        <Text>İşleme ait hazırlanan temliknameyi buradan indirin.</Text>
                        <Button onClick={() => handleTemlikDownload(btn1)} className="download-temlik">
                            <DownloadOutlined className="download-icon" />
                            Temliknameyi İndir
                        </Button>
                        <span>ya da</span>
                        <Button onClick={() => handleTemlikDownload(btn2)} className="download-temlik" disabled={mailLoading}>
                            <MailOutlined className="download-icon" /> 
                            E-posta Olarak Gönder
                        </Button>
                        <Divider type="vertical" className="files-divider" />
                        <div className="num-round">2</div>
                       
                        <p style={{ maxWidth:'800px'}}> <ExclamationOutlined  style={{fontSize:'32px', color:'#f00', fontWeight:'bold'}} /> <br/>
                         Temliknameyi bastırıp kaşeli ve imzalı olarak buraya yükleyin. <br/>
                        Yalnızca <strong> PDF, JPG, JPEG ve PNG </strong> uzantılı dosyaları yükleyebilirsiniz.
                         Birden fazla <strong> PDF </strong> ya da diğer belirtilen uzantılı dosyaları seçip  aynı anda yükleyebilirsiniz.
                          Ancak <strong> PDF </strong> uzantılı dosyalar ile diğer uzantılı dosyaları <strong> aynı anda yükleyemezsiniz.</strong><br/>
                        Toplam dosya boyutu <strong>10 MB'ı </strong> aşamaz.
                        </p>
                        <Upload
                            listType="picture-card"
                            className="temlik-upload"
                            multiple 
                            accept="application/pdf,image/png,image/jpeg,image/jpg"
                            loading={isLoadingTemlikFile}
                            beforeUpload={(file) => {
                                setTemlikFile((prevFiles) => [...prevFiles, file]);
                                return false; 
                            }}
                            onRemove={handleRemove} 
                            showUploadList={show}
                        >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Icon icon="plus" color="inherit" />
                                    <Text className="d-block" bold>
                                        Temliknameyi Yükle 
                                    </Text>
                            </div>
                        
                        </Upload>
                        <Divider type="vertical" className="files-divider" />
                        <div className="num-round">3</div>
                        <Text>İmzali ve kaşeli bir kopyayı da şubeye iletin.</Text>
                    </SuccessBox>
                </Col>
            </Row>
        </Spin> 
        
        <Modal
            title="Temlikname Önizleme"
            visible={visible}
            closable={false} 
            maskClosable={false} 
            footer={[
                <Button key="cancel" onClick={() => modalClear()}>
                İptal
                </Button>,
                <Button key="ok" type="primary" onClick={() => uploadTemlikFileSend(temlikFile)}>
                Finans Kurumuna Gönder
                </Button>,
            ]}
            width={900}
        >
            {pdfUrl && (
            <iframe
                src={pdfUrl}
                title="PDF Önizleme"
                width="100%"
                height="500px"
            />
            )}
        </Modal>
        </>
    );
};

export default SuccessMsg;
