/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    invoices: [],
    invoiceResId: 0,
    buyerData : [],
    buyerIdentity :'',
    financialInstitution:[],
    financialList :[],
    risks :[],
    banks :[],
    totalInvoice:0,
    averageMaturitys:[],
    selectInvoices:[],
    selectbuyers:{},
    discountInvoices:[]
};

const supplierFinanceSlice = createSlice({
    name: 'supplierFinance',
    initialState,
    reducers: {
        setInvoices(state, action) {
            state.invoices = action.payload;
        },
        setFinancialInstitution(state, action) {
            state.financialInstitution = action.payload;
        },
        setFinancialList(state, action) {
            state.financialList = action.payload;
        },
        setInvoiceResId(state, action) {
            state.invoiceResId = action.payload;
        },
        setBuyersList(state, action){
            state.buyerData = action.payload
        },
        setBuyerIdentity(state, action){
            state.buyerIdentity = action.payload
        },
        setRisk(state, action){
            state.risks = action.payload
        },
        setBankList(state, action){
            state.banks = action.payload
        },
        setTotalInvoices(state, action){
            state.totalInvoice = action.payload
        },
        setAverageMaturitys(state, action){
            state.averageMaturitys = action.payload
        },
        setSelectInvoices(state, action){
            state.selectInvoices = action.payload
        },
        setSelectBuyers(state, action){
            state.selectbuyers = action.payload
        },
        setDiscountInvoices(state, action){
            state.discountInvoices = action.payload
        }
    },
});

export const { setInvoices, setInvoiceResId, setBuyersList, setBuyerIdentity, setFinancialInstitution, setFinancialList,setRisk,setBankList,setTotalInvoices, setAverageMaturitys,setSelectInvoices, setSelectBuyers, setDiscountInvoices} = supplierFinanceSlice.actions;
export default supplierFinanceSlice.reducer;
