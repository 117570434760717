import React, { useState, useEffect } from 'react';
import { Col, Drawer, Row, Space, Tag, Menu, Dropdown, Collapse, Typography } from 'antd';
import { UserOutlined} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';
import LOGO from '../../../assests/logo-horizontal.png';
import Button from '../../Button';

import StyledHeader, { DrawerButton } from './styles';
import AccountDropdown from '../../AccountDropdown';
import useResponsive from '../../../hooks/useResponsive';
import urls from '../../../routes/urls';
import { fetchUser ,  logoutApi, usersCurrent} from '../../../apiServices/userApi';
import { setBuyerIdentity } from '../../../store/reducers/supplierFinanceSlice';
import { setUser,setLoggedIn, setUsersCurrent} from '../../../store/reducers/userSlice';
import LocalStorageService from '../../../services/LocalStorageService';

function Header({ getOnboardStatus }) {
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const { xl, xxl } = useResponsive();

    const { isLoggedIn, user } = useSelector((state) => state.user);
    const { risks } = useSelector((state) => state.supplierFinance);
    const [navShow, setNavShow] = useState(false);

    const isAdmin = JSON.parse(LocalStorageService.getIsAdmin());
    const token =  LocalStorageService.getAuthToken();
    const buyerIdentity = LocalStorageService.getBuyerUser() ? `${LocalStorageService.getBuyerUser().substring(0, 10)}.` : '';
   
    const { Panel } = Collapse
    const { Text } = Typography;
    const path = location.pathname;
    const getButtonSize = () => {
        if (xxl) return 'large';
        return 'middle';
    };

    const showDrawer = () => {
        setNavShow(true);
      };
      const onClose = () => {
        setNavShow(false);
      }; 
  
    const onClickLogout = async () => {
        LocalStorageService.setIsLogout(true);
        await logoutApi();
        dispatch(setLoggedIn(''));
        dispatch(setUser(''));
        dispatch(setBuyerIdentity(''));

        history.push(urls.login);
        setNavShow(false);
         LocalStorageService.removeAuthToken();
         LocalStorageService.removeUserEmail()
         LocalStorageService.removeBuyerUser();
         LocalStorageService.removeIsAdmin();
         LocalStorageService.removeIsLogout();
         window.location.reload();
      };

    const headerItems = [
        {
            text: 'Başvuru Yap',
            url: urls.supplierFinancing,
            icon: 'supplierFinancing',
            isNew: false,
            className: 'yuklenen-fatura'
        },
        { text: 'Başvurularım', url: urls.funds, icon: 'fund', className: 'basvurular' },
    ];
 
    const getUserData = async () => {
        const response = await fetchUser(isLoggedIn);
        if (response) {
            dispatch(setUser(response));
        }
    }; 
    
    const getUsersCurrent = async () => {
        const response = await usersCurrent(isLoggedIn);
        if (response) {
            dispatch(setUsersCurrent(response));
        }
    };  
 
    useEffect(() => {
        if (user && user.id && isLoggedIn) {
          getOnboardStatus();
        }
        !user && isLoggedIn && getUserData() && getUsersCurrent();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, isLoggedIn]);
  
    return (
        <StyledHeader className="light">
            <Row className="h-100 header-row" justify="space-between" align="middle">
                <Col xs={15} md={8} lg={4}>
                    <Link to={urls.supplierFinancing}>
                        <img className="header-logo" src={LOGO} alt="header-logo"  />
                    </Link>
                </Col>
{
    token && (
        <Col xs={0} xl={13} style={ isAdmin ? { paddingLeft: '1em' } :  { paddingLeft: '7em' } }>
        <Row className={ isAdmin  ? 'menu-draw menu-draw-admin' : 'menu-draw'} >
            <Space className={!buyerIdentity ? 'adisabled' : ''} >
                {headerItems.map(({ text, url, isNew, onClick, className }) => (
                    <Link key={`header-link-${url}`} to={url}  >
                        <Button
                            className={`header-btn ${className} ${path === url && 'active-line'}`}
                            type="transparent"
                            size={getButtonSize()}
                            active={path === url}
                            onClick={onClick}>                                        
                            {text}
                            {isNew && (
                                <Tag className="ml" color="green">
                                    Yeni
                                </Tag>
                            )}
                        </Button>
                    </Link>
                ))}

                <Dropdown
                    overlay={(
                        <Menu className='menu-style' style={{padding:'10px 20px', borderRadius:'6px'}}>
                            <Menu.Item key="0">
                                <Link to={urls.lateInvoices}>
                                    Alıcı Ödemesi Geciken Faturalarım
                                </Link>                                            
                            </Menu.Item>
                            <Menu.Item key="1">
                                <Link to={urls.invoicesPendingPayment}>
                                    Ödeme Vadesini Bekleyen Faturalarım
                                </Link>                                             
                            </Menu.Item>
                            <Menu.Item key="2">
                                <Link to={urls.paidInvoices}>
                                    Alıcı Ödemesi Gerçekleşen Faturalarım
                                </Link>                                             
                            </Menu.Item>
                            <Menu.Item key="2">
                                <Link to={urls.bankRiskLimit}>
                                 Risk/Limit Bilgilerim
                                </Link>                                             
                            </Menu.Item>
                        </Menu>
                    )}
                    trigger={['click']}>
                    <Link> 
                        <Button
                             className={`header-btn temlik-fatura 
                             ${path === '/geciken-faturalar' || path === '/odeme-bekleyen-faturalar' ||
                             path === '/odenenler-faturalar' || path === '/banka-risk-limit' 
                             ? 'active-line' : '' }`}
                             type="transparent"
                             size={getButtonSize()}
                        >
                            <span>Raporlar</span>
                        </Button>
                        
                    </Link>
                </Dropdown>

            </Space>
        </Row>
    </Col>
    )
}
               

                {
                    !buyerIdentity ||  !risks ? '' :
                    <Col xs={0} xl={5} >
                        <Row>
                            <div className="buyer-col">
                                <h4 style={{fontSize:'12px'}}>{buyerIdentity} /</h4>
                                <Link to={urls.buyers}>
                                    <Button style={{fontSize:'12px'}}>Alıcı Değiştir</Button>
                                </Link>
                            </div>                
                        </Row>
                    </Col>
                }
                              

                {xl ? (
                    <Col xs={0} xl={3}>
                        {isLoggedIn ? (
                            <AccountDropdown />
                        ) : (
                            <Link to={urls.login}>
                                <Button type="primary" size={getButtonSize()}>
                                    Giriş Yap / Üye Ol
                                </Button>
                            </Link>
                        )}
                    </Col>
                ) : (
                    <>
                        <Button className={`menu-toggle  ${navShow ? 'open': ''}`}  onClick={showDrawer} >
                            <span className='hamburger'/>
                        </Button>
                        <Drawer
                        title={
                            <Link to={urls.supplierFinancing}>
                            <img className="header-logo" src={LOGO} alt="Depar.io" style={{width:'150px', marginLeft:'8px'}}  />
                        </Link>
                            }
                          placement="left"
                          width={350}
                          onClose={onClose}
                          open={navShow}
                            
                        >
                        {
                           isLoggedIn ? (
                            <div className="mobil-userName">
                                <div className="left">
                                    <UserOutlined />
                                    <p>{LocalStorageService.getUserEmail()}</p>
                                </div>
                                <div style={{textAlign:'right'}}>
                                    <Button onClick={onClickLogout}>
                                        <Text className='m-0 mobil-user' style={{ color: 'red !important', fontSize:'12px !important' }}>
                                            Çıkış 
                                        </Text>
                                    </Button>
                                </div>  
                            </div>
                            ):
                            ''
                         }
                        {
                            !buyerIdentity ||  !risks ? '' :
                                <div className="buyer-col mobil-buyer-col">
                                    <div className='mobil-buyer-title' style={{display:'flex', flexDirection:'column'}}>
                                        <span>Alıcı:</span>
                                        <h4 style={{fontSize:'12px'}}>{buyerIdentity} /</h4>
                                    </div>
                                    <Link to={urls.buyers}>
                                        <Button>Alıcı Değiştir</Button>
                                    </Link>
                                </div>                
                             
                            }
                            {token && headerItems.map(({ text, url }) => (
                                <Link key={`drawer-link-${url}`} to={url}  className={!buyerIdentity ? 'adisabled mobil-menu' : ' mobil-menu'}>
                                    <DrawerButton
                                        type="transparent"
                                        size={getButtonSize()}
                                        active={path === url}
                                        className={!buyerIdentity ? 'adisabled ' : ' '}
                                        block>
                                        {text}
                                    </DrawerButton>
                                </Link>
                            ))}
                            {
                                token && (
                                    <>
                                         <Collapse  expandIconPosition="right" >
                                <Panel header="Raporlar"  >
                                    <Link to={urls.lateInvoices} className={!buyerIdentity ? 'adisabled' : ''} >
                                        Alıcı Ödemesi Geciken Faturalarım 
                                    </Link>
                                    <Link to={urls.invoicesPendingPayment} className={!buyerIdentity ? 'adisabled' : ''} >
                                        Ödeme Vadesini Bekleyen Faturalarım
                                    </Link>
                                    <Link to={urls.paidInvoices} className={!buyerIdentity ? 'adisabled' : ''} >
                                        Alıcı Ödemesi Gerçekleşen Faturalarım
                                    </Link>
                                    <Link to={urls.bankRiskLimit} className={!buyerIdentity ? 'adisabled' : ''} >
                                        Risk/Limit Bilgilerim
                                    </Link>
                                </Panel>
                            </Collapse>
                         
                            <Collapse  expandIconPosition="right" >
                                <Panel header="Hesabım" block  >
                                    <Link to={urls.generalInfo} className={!buyerIdentity ? 'adisabled' : ''} >
                                        Kullanıcı Bilgileri
                                    </Link>
                                    <Link to={urls.companyInfo} className={!buyerIdentity ? 'adisabled' : ''} >
                                        Firma Bilgileri
                                    </Link>
                                    <Link to={urls.changePassword} className={!buyerIdentity ? 'adisabled' : ''} >
                                        Şifre İşlemleri
                                    </Link>
                                    <Link to={urls.legalDocuments} className={!buyerIdentity ? 'adisabled' : ''} >
                                         Firma Belgeleri
                                    </Link>
                                    {
                                        isAdmin ? (
                                            <>
                                             <Link to={urls.newUser} className={!buyerIdentity ? 'adisabled' : ''} >
                                                Kullanıcı Tanımlama
                                            </Link>
                                            <Link to={urls.userTracing} className={!buyerIdentity ? 'adisabled' : ''} >
                                                 Kullanıcı İzleme
                                            </Link>
                                            </>
                                        ) : ''
                                    }
                                    <Link to={urls.help} className={!buyerIdentity ? 'adisabled' : ''}>
                                        Yardım
                                    </Link>
                                </Panel>
                            </Collapse>
                                    </>
                                )
                            }
                           

                            {isLoggedIn ? (
                                <div className="mt-big" />
                            ) : (
                                <Link to={urls.login}>
                                    <Button
                                        className="mt"
                                        type="primary"
                                        size={getButtonSize()}
                                        active={path === urls.funds}
                                        block>
                                        Giriş Yap / Kayıt Ol
                                    </Button>
                                </Link>
                            )}
                        </Drawer>
                    </>
                )}
            </Row>
        </StyledHeader>
    );
}

export default Header;
