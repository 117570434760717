import React, { useEffect, useState } from 'react';
import { Row, Col, Table, Button, Divider, Tabs, Spin, Modal, notification } from 'antd';
import { ExclamationCircleOutlined, ReloadOutlined, HourglassOutlined, CheckCircleOutlined, CloseCircleOutlined  } from '@ant-design/icons';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { discountsConveyanceApi } from '../../apiServices/supplierFinanceApi';
import Icon from '../../components/Icon/index';
import Text from '../../components/Text';
import { chequeStatusMapByValue } from '../../constants';
import { convertFloatDotSeperated } from '../../utils';
import { setDiscountInvoice } from '../../store/reducers/fundSlice';
import { LeftSideBox, BottomSideBox, ScreenContent } from './styles';
import { fetchDiscountInvoiceById, fetchInvoiceOffer, acceptInvoiceOffer, getImportTemplate } from '../../apiServices/fundApi';
import SuccessMsg from './SuccessMsg';

const FundDetail = () => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const [offers, setOffers] = useState();
    const [successRes, setSuccessRes] = useState(false);
    const [loading, setLoading] = useState(false);
    const { discountInvoice, certifiedInvoice, unapprovedInvoice } = useSelector((state) => state.fund);
    const { user } = useSelector((state) => state.user);
    const [approvedTotal, setApprovedTotal] = useState(0);
    const [unapprovedTotal, setUnapprovedTotal] = useState(0)
    const [dueDate, setDueDate] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showModal, setShowModal] = useState(false);
  
    const status = (discountInvoice?.status && chequeStatusMapByValue[discountInvoice?.status]) || {};
    const statusColor = status?.color || 'smoke';
    // const statusDesc = status?.desc || '';
    const { TabPane } = Tabs;
   
    const fetchFinancialbyId = async () => {       
        if (id) {
            setLoading(true)
            const response = await fetchDiscountInvoiceById(id);
            if(response){
                setLoading(false)
                dispatch(setDiscountInvoice(response));
            }
        }
    };

    const getInvoiceOffer = async () => {
        if (id) {
            const response = await fetchInvoiceOffer(id);
            setOffers(response.map(item => item));
        }
    };

    const approvedTotals = (data) => {  
        let totalInvoice = 0;
           data && data.map((val) => {
                totalInvoice += parseFloat(val.invoiceTotal);
                return val;
            });
            setApprovedTotal(totalInvoice)
        return totalInvoice;
      };
    
    const unapprovedTotals = (data) => {  
        let totalInvoice = 0;
           data && data.map((val) => {
                totalInvoice += parseFloat(val.invoiceTotal);
                return val;
            });
            setUnapprovedTotal(totalInvoice)
        return totalInvoice;
      };
    
      const totalDaysCal = (date1, date2) => {
        const difference = date1.getTime() - date2.getTime();
        const TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
        return TotalDays;
    };
    
    const getSupplierAvgDate = (data) => {
        let totalDays = 0;  
        let totalAmount = 0;  
        let termDay = 0;
          data &&  data.map((val) => {
                const totalDaysInvoice = totalDaysCal(new Date(val.invoiceTerm), new Date());
                if (totalDaysInvoice > 0) {
                    totalDays += totalDaysInvoice * val.invoiceTotal;
                    totalAmount += parseFloat(val.invoiceTotal);
                    termDay = totalDays / totalAmount;
                }
                return val;
            });
            setDueDate(Math.floor(termDay))
        return totalDays;
    };

    const handleOver = async () => {
        setLoading(true);
        try{
           
            await discountsConveyanceApi(id);
            notification.success({ message: 'İşlem başarıyla gerçekleşti.' });
            setLoading(false);
            setIsModalOpen(false);
            setTimeout(()=>{
                window.location.reload(false);
            }, 1500); 
        }catch(e){
            console.log(e);
            setLoading(false)
        }
    }
    const handleCancel = () => {
        setLoading(false);
        setIsModalOpen(false);
    }

    useEffect(() => {
        if (status.value === 'CEVAP_GELDI') {
            getInvoiceOffer();
        }
        if (status.value === 'SURESI_DOLDU' && discountInvoice.offer === null ) {
           
            getInvoiceOffer();
        }     
        approvedTotals(certifiedInvoice) 
        getSupplierAvgDate(certifiedInvoice.length ? certifiedInvoice : discountInvoice.invoices) 
        unapprovedTotals(unapprovedInvoice)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, discountInvoice,certifiedInvoice,unapprovedInvoice]);

    useEffect(() => {
        fetchFinancialbyId();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

const statusOutput = (value) =>  {
    if(value === 'ON_ODEME_YAPILDI'){
        return <h4 style={{color: '#62b61f'}}>Ön Ödeme Yapıldı</h4>
    }
    if(value === 'ODENDI'){
        return <h4 style={{color: '#40a9ff'}}>Ödendi</h4>
    }
    if(value === 'SECILDI'){
        return  <CheckCircleOutlined style={{color: '#62b61f', fontSize: 22, marginLeft: 25}} />
    }
    if(value === 'SECIM_YAPILMADI'){
        return <HourglassOutlined style={{color: '#f5ee12', fontSize: 22, marginLeft: 25}} />
    }
    if(value === 'IPTAL_EDILDI'){
        return <CloseCircleOutlined style={{color: '#cc2c2c', fontSize: 22, marginLeft: 25}} />
    }
    if(value === ''){
        return ''
    }
    if(value !== 'ON_ODEME_YAPILDI' && value !== 'SECILDI' && value !== 'SECIM_YAPILMADI' && value !== 'ODENDI'){
        return <CloseCircleOutlined style={{color: '#cc2c2c', fontSize: 22, marginLeft: 25}} />
    }   
     
}

    const tableCols = [
        {
            title: 'Fatura No',
            dataIndex: 'invoiceNumber',
            key: 'invoiceNumber',
        },
        {
            title: 'Fatura Tarihi',
            dataIndex: 'invoiceDate',
            key: 'invoiceDate',
            render: (date) => moment(date).format('DD-MM-YYYY'),
        },
        {
            title: 'Alıcı VKN',
            dataIndex: 'invoiceOwnerTaxNumber',
            key: 'invoiceOwnerTaxNumber',
        },        
        {
            title: 'Fatura Tutarı',
            dataIndex: 'invoiceTotal',
            key: 'invoiceTotal',
            render: (price) => `${convertFloatDotSeperated(price)} ₺`,
        },
        {
            title: 'Fatura Vadesi',
            dataIndex: 'invoiceTerm',
            key: 'invoiceTerm',
            render: (date) => moment(date).format('DD-MM-YYYY'),
        },  
        {
            title: 'Onay Durumu',
            dataIndex: 'status',
            key: 'status',
            render: (value) => statusOutput(value)
          },
    ];

    const acceptOffer = async (ofer,show) => {
        setLoading(true);
        const response = await acceptInvoiceOffer(
            ofer.discountId,
            ofer.financialInstitutionId,
            {
                
                discountApplicationId: ofer.discountId,
                financialInstitutionId: ofer.financialInstitutionId,
                discountApplicationStatus: discountInvoice?.status,
                offer: ofer.offer,
                offerCondition: ofer.offerCondition,
            }
        );
        if (response) {
            if(show){
                setLoading(false);
                setSuccessRes(true);
                fetchFinancialbyId();
                notification.success({
                    message:
                        'Onayınız finans kurumuna bildirildi. İşlemi tamamlamak için sizin için ürettiğimiz temliknameyi bastırıp kaşeli ve imzalı olarak en yakın şubeye götürebilirsiniz',
                });
            }else{
                setLoading(false);
                fetchFinancialbyId();
                setShowModal(true);
            }
        }
    };

    const downloadSample = async () => {
        try {
            setLoading(true)
          const response = await getImportTemplate(id);
          if (response) {
            const link = document.createElement('a');
            link.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64, ${encodeURIComponent(response.base64Format)}` ;
            link.setAttribute('download', `Temliknameİmzali-${(discountInvoice?.financialInstitutionName.split(' ')[0])}-${discountInvoice?.number}.pdf`); 
          
            link.style.display = 'none';
            document.body.appendChild(link);      
            link.click();      
            document.body.removeChild(link);
            setLoading(false)
          }
          
        } catch (e) {
          console.log(e);
        }
    };

    return (
        <Spin spinning={loading}>
            {successRes ? (
                <SuccessMsg 
                    supplierId={user.id}
                    discountId={id} 
                    invoiceData={discountInvoice} 
                    financialInstitutionName={discountInvoice?.financialInstitutionName}
                    financialInstitutionTaxId={discountInvoice?.financialInstitutionTaxId}
                />
            ) : (
                <Row className="mt" gutter={[10, 20]}>
                    <Col md={24} lg={6} xs={24}>
                        <LeftSideBox>
                            <Text className='page-title'>Başvuru Detayı</Text>
                            <Row className="status-box">
                                <div style={{ display: 'flex' }}>
                                        {status.value === 'TEKLIF_BEKLIYOR' && <HourglassOutlined style={{fontSize:'24px', color:statusColor}} />}
                                        {status.value === 'CEVAP_GELDI' && <Icon style={{fontSize:'24px', color:statusColor}} icon={['far', 'envelope']} />}
                                        {status.value === 'SURESI_DOLDU' && <Icon style={{fontSize:'24px', color:statusColor}} icon={['far', 'angry']} />}
                                        {status.value === 'TEKLIFI_ONAYLADIM' && <Icon style={{fontSize:'24px', color:statusColor}} icon={['far', 'thumbs-up']} />}
                                        {status.value === 'ISLEME_ALINDI'&& <ReloadOutlined style={{ fontSize: '24px', color: statusColor }} />}
                                        {status.value === 'TEMLIKNAME_YUKLENDI'&& <Icon style={{ fontSize: '24px', color: statusColor }} icon={['far', 'thumbs-up']} />}
                                        {status.value === 'IPTAL_EDILDI' && <Icon style={{fontSize:'24px', color:statusColor}} icon={['far', 'angry']} />}
                                        
                                        
                                        
                                    <Text className="status-title" style={{ color: statusColor }}>
                                        {status.text}
                                    </Text>
                                </div>
                                <ExclamationCircleOutlined style={{ fontSize: '20px', color: '#727272' }} />
                            </Row>
                            <Row className="item-box">
                                <Col>
                                <Row className="item-row">
                                    <Text className="item-title">Finans Kurum</Text>
                                        <Text className="item-value">
                                            {discountInvoice?.financialInstitutionName || '-'}
                                        </Text>
                                    </Row>
                                {status &&
                                        (status.value === 'ONAYLADIM' ||
                                            status.value === 'TEKLIFI_ONAYLADIM') && (
                                            <>
                                                <Row className="item-row">
                                                    <Text className="item-title">Teklif Tutarı</Text>
                                                    <Text className="item-value">
                                                        {convertFloatDotSeperated(discountInvoice.offer)} TL
                                                    </Text>
                                                </Row>
                                               
                                                {status && status.value === 'TEKLIFI_ONAYLADIM' && certifiedInvoice.length > 0  &&  (
                                                    discountInvoice?.financialInstitutionTaxId === '0150015264'  ? 
                                                        <Button
                                                            className="left-btn over-btn"
                                                            type="primary"
                                                            htmlType="submit"
                                                            loading={loading}
                                                            onClick={() => setIsModalOpen(true)}
                                                            block>
                                                            Temliknameyi Teslim Ettim
                                                        </Button> :
                                                        <Button
                                                            className="left-btn"
                                                            type="primary"
                                                            htmlType="submit"
                                                            loading={loading}
                                                            onClick={() => setSuccessRes(true)}
                                                            block>
                                                            Temliknameyi Yükle 
                                                    </Button>
                                                )} 
                                            </>
                                        )}
                                    {status &&
                                        status.value === 'CEVAP_GELDI' &&
                                        offers &&
                                        offers.map((ofer) => (
                                            <>
                                                <Row className="item-row">
                                                    <Text className="item-title">Teklif Tutarı</Text>
                                                    <Text className="item-value">
                                                        {convertFloatDotSeperated(ofer.offer)} TL
                                                    </Text>
                                                </Row>
                                       
                                                {
                                                    discountInvoice?.financialInstitutionTaxId === '0150015264' ?
                                                   
                                                        <Button
                                                            className="left-btn"
                                                            type="primary"
                                                            htmlType="submit"
                                                            loading={loading}
                                                            onClick={() => acceptOffer(ofer, false)}
                                                            block
                                                        >
                                                            Teklifi Kabul Et 
                                                        </Button>
                                                    :
                                                        <Button
                                                            className="left-btn"
                                                            type="primary"
                                                            htmlType="submit"
                                                            loading={loading}
                                                            onClick={() => acceptOffer(ofer,true)}
                                                            block
                                                            >
                                                            Teklifi Kabul Et
                                                        </Button>  
                                                    
                                                    }
                                            </>
                                        ))}
                                      {status &&
                                        status.value === 'TEMLIKNAME_YUKLENDI'  && (
                                            <>
                                                <Row className="item-row">
                                                    <Text className="item-title">Teklif Tutarı</Text>
                                                    <Text className="item-value">
                                                        {convertFloatDotSeperated(discountInvoice.offer)} TL
                                                    </Text>
                                                </Row>

                                                {status && status.value === 'TEMLIKNAME_YUKLENDI' && certifiedInvoice.length > 0 && (
                                                    discountInvoice?.financialInstitutionTaxId !== '0150015264' ?
                                                    <Button
                                                        className="left-btn"
                                                        type="primary"
                                                        htmlType="submit"
                                                        loading={loading}
                                                        onClick={() => downloadSample(id)}
                                                        block>
                                                        Temliknameyi İndir
                                                    </Button>
                                                    : ''
                                                )}
                                               
                                            </>
                                        )}
                                     {status &&
                                       ( status.value === 'SURESI_DOLDU' && discountInvoice.offer  )  
                                        && (
                                            <>
                                                <Row className="item-row">
                                                    <Text className="item-title">Teklif Tutarı</Text>
                                                    <Text className="item-value">
                                                        {convertFloatDotSeperated(discountInvoice.offer)} TL
                                                    </Text>
                                                </Row>
                                            
                                            </>
                                        )}
                                        {status &&
                                        status.value === 'SURESI_DOLDU' && discountInvoice.offer === null &&
                                        offers &&
                                        offers.map((ofer) => (
                                            <>
                                                <Row className="item-row">
                                                    <Text className="item-title">Teklif Tutarı</Text>
                                                    <Text className="item-value">
                                                        {convertFloatDotSeperated(ofer.offer)} TL
                                                    </Text>
                                                </Row>
                                              
                                                {/* <Button
                                                    className="left-btn"
                                                    type="primary"
                                                    htmlType="submit"
                                                    loading={loading}
                                                    onClick={() => acceptOffer(ofer)}
                                                    block>
                                                    Teklifi Kabul Et
                                                </Button> */}
                                            </>
                                        ))}
                                    <Divider />
                                    <Row className="item-row">
                                        <Text className="item-title">Başvuru No</Text>
                                        <Text className="item-value">{discountInvoice?.number || '-'}</Text>
                                    </Row>
                                    <Row className="item-row">
                                        <Text className="item-title">Alıcı</Text>
                                        <Text className="item-value">{discountInvoice?.buyerTitle}</Text>
                                    </Row>
                                    <Row className="item-row">
                                        <Text className="item-title">İşlem Tarihi</Text>
                                        <Text className="item-value">
                                            {moment(discountInvoice?.date).format('DD-MM-YYYY') || '-'}
                                        </Text>
                                    </Row>
                                    <Row className="item-row">
                                        <Text className="item-title">Toplam Fatura Tutarı</Text>
                                        <Text className="item-value">
                                            {discountInvoice?.invoicesTotal &&
                                                convertFloatDotSeperated(discountInvoice?.invoicesTotal)}{' '}
                                            ₺
                                        </Text>
                                    </Row>
                                    <Row className="item-row">
                                        <Text className="item-title">Ortalama Vade</Text>
                                        <Text className="item-value">
                                             {discountInvoice?.averageMaturity || '-'}
                                        </Text>
                                    </Row>
                                    <Row className="item-row">
                                        <Text className="item-title">Fatura Adet</Text>
                                        <Text className="item-value">
                                            {discountInvoice?.invoicesCount || '-'}
                                        </Text>
                                    </Row>
                                    
                                     
                                </Col>
                            </Row>
                        </LeftSideBox>
                    </Col>
                    {discountInvoice?.invoices?.length > 0 && (
                        <Col md={22} lg={16} xs={24} offset={1}>
                            {
                                status &&
                                (status.value === 'ISLEME_ALINDI' ||status.value === 'TEKLIF_BEKLIYOR' ||status.value === 'SURESI_DOLDU' ||status.value === 'IPTAL_EDILDI') && (
                                    <ScreenContent>
                                        <Table
                                            pagination={false}
                                            rowKey="index"
                                            dataSource={discountInvoice?.invoices}
                                            columns={tableCols}
                                        />
                                    </ScreenContent>
                                     
                                )
                            }
                            {
                                status &&
                                (status.value === 'CEVAP_GELDI' ||status.value === 'TEKLIFI_ONAYLADIM' ||status.value === 'TEMLIKNAME_YUKLENDI') && (
                                    <ScreenContent>
                                    <Tabs type="card">
                                        <TabPane tab="Onaylanan Faturalar" key="1">
                                            {
                                                certifiedInvoice <= 0 ? 
                                                <p style={{padding:'20px', fontSize:'15px', fontWeight: '500'}}>
                                                    Onaylanmış faturanız bulunmamaktadır.</p> 
                                                :
                                                <>
                                                    <BottomSideBox>
                                                        <div className='box-div'>
                                                            <div className='header-title'>
                                                                Onaylanan Faturalar:
                                                            </div>
                                                            <div className='header-table'>
                                                                <span className='text-title'>Adet</span>
                                                                <span>{certifiedInvoice.length}</span>
                                                            </div>
                                                            <div className='header-table'>
                                                                <span className='text-title'>Tutar</span>
                                                                <span>{convertFloatDotSeperated(approvedTotal)} ₺</span>
                                                            </div>
                                                            <div className='header-table'>
                                                                <span className='text-title'>Ortalama Vade</span>
                                                                <span>{dueDate}</span>
                                                            </div>
                                                        </div>
                                                    </BottomSideBox>
                                                    <Table
                                                        pagination={false}
                                                        rowKey="index"
                                                        dataSource={certifiedInvoice}
                                                        columns={tableCols}
                                                        scroll={{
                                                            x: 750,
                                                          }}
                                                    />
                                                </>
                                            }
                                            
                                        </TabPane>
                                        <TabPane tab="Onaylanmayan Faturalar" key="2">
                                            {
                                                unapprovedInvoice.length <= 0 ?
                                                <p style={{padding:'20px', fontSize:'13px', fontWeight: '500'}}>
                                                    Onaylanmamış faturanız bulunmamaktadır.
                                                </p>
                                                :
                                                <>
                                                    <BottomSideBox>
                                                        <div className='box-div'>
                                                            <div className='header-title'>
                                                                Onaylanan Faturalar:
                                                            </div>
                                                            <div className='header-table'>
                                                                <span className='text-title'>Adet</span>
                                                                <span>{unapprovedInvoice.length}</span>
                                                            </div>
                                                            <div className='header-table'>
                                                                <span className='text-title'>Tutar</span>
                                                                <span>{convertFloatDotSeperated(unapprovedTotal)} ₺</span>
                                                            </div>
                                                        </div>
                                                    </BottomSideBox>
                                                    <Table
                                                        pagination={false}
                                                        rowKey="index"
                                                        dataSource={unapprovedInvoice}
                                                        columns={tableCols}
                                                        scroll={{
                                                            x: 750,
                                                          }}
                                                    />
                                                </>
                                            }    
                                        </TabPane>
                                    </Tabs>
                                    </ScreenContent> 
                                )
                            }
                          
                        </Col>
                    )}
                </Row>
            )}
              <Modal
                open={isModalOpen}
                closable={false}
                maskClosable={false}
                footer={[
                <Button key="ok" type="primary" onClick={handleOver}>
                    Tamam
                </Button>,
                <Button key="pass" type="default" onClick={handleCancel}>
                    İptal
                </Button>,
                ]}
            >
            <p>
            İmzalı kredi sözleşmesini Akbank şubesine teslim ettiğinizi beyan ediyorsunuz. Kabul etmeniz durumunda işleminize devam edilecektir.
            </p>
            </Modal> 

            <Modal
                open={showModal}
                closable={false}
                maskClosable={false}
                footer={[
                <Button key="ok" type="primary" onClick={() => setShowModal(false)}>
                    Tamam
                </Button>,
                ]}
            >
            <p>
            İşlem onayınız Akbank'a iletilmiştir. Ödeme için imza yetkilinizin Akbank şubesinde Alacak Bildirim Formu'nu imzalaması gerekmektedir. İmza sonrası, başvurunuza yeniden girerek 'Temliknameyi Teslim Ettim' butonuna tıklayıp işlemi tamamlayabilirsiniz.
            </p>
            </Modal> 
        </Spin>
        
    );
};

export default FundDetail;
